export default {
  /* eslint-disable max-len */
  name: "MoreAccessorials",
  props: {
    departmentId: {
      type: Number,
    },
    quoteMode: String,
  },
  data() {
    return {
      searchQuery: "",
      accessorials: null,
      selectedAccessorialsVmodel: {
        general: {},
        pickup: {},
        delivery: {},
      },
      chosenAccessorials: [],
      vuexAccessorials: null,
      isBranded: null,
    };
  },
  created() {
    this.vuexAccessorials = this.$store.getters["load/accessorials/getClassifiedAccessorials"];
    this.accessorials = JSON.parse(JSON.stringify(this.vuexAccessorials));
    this.checkAccessorials();
    this.isBranded = this.$store.getters["login/getTokenInfo"].is_branded;
  },
  watch: {
    searchQuery(query) {
      Object.keys(this.accessorials[this.departmentId]).forEach((key) => {
        this.accessorials[this.departmentId][key] = this.vuexAccessorials[this.departmentId][
          key
        ].filter(
          (accessorial) => accessorial.name.toLowerCase().includes(query.toLowerCase()) && !accessorial.most_common
        );
      });
    },
  },
  methods: {
    checkAccessorials() {
      const chosenAccessorials = this.$store.getters["load/accessorials/getChosenAccessorials"];
      chosenAccessorials.forEach((accessorial) => {
        if (!accessorial.most_common) {
          this.selectedAccessorialsVmodel[accessorial.accessorial_type.replace(" ", "")][
            accessorial.id
          ] = true;
        }
      });
      this.chosenAccessorials = chosenAccessorials.filter(
        (accessorial) => !accessorial.most_common
      );
    },
    async selectAccessorial(value, accessorial) {
      if (value) {
        this.$store.commit("load/setUpdatingRequestInProcess", true);
        await this.addAccessorial(accessorial);
        this.$store.commit("load/setUpdatingRequestInProcess", false);
      } else {
        this.$store.commit("load/setUpdatingRequestInProcess", true);
        await this.deleteAccessorial(accessorial);
        this.$store.commit("load/setUpdatingRequestInProcess", false);
      }
    },
    clearSearchFilter() {
      this.searchQuery = "";
    },
    closeModal() {
      this.chosenAccessorials = [];
      this.$emit("input", false);
    },
    addAccessorial(accessorial) {
      this.$store.commit("load/accessorials/setChosenAccessorials", { ...accessorial });
      // if (this.$route.params.loadId) {
      //   this.postAccessorialRequest(accessorial);
      // }
    },
    deleteAccessorial(accessorial) {
      this.$store.commit("load/accessorials/deleteFromChosenAccessorials", { ...accessorial });
      // if (this.$route.params.loadId) {
      //   this.deleteAccessorialRequest(accessorial);
      // }
    },
    async postAccessorialRequest(accessorial) {
      if (this.$options.filters.checkPermission("post:load-accessorial")) {
        const { loadId } = this.$route.params;
        const body = {
          id_load: loadId,
          accessorial_id: accessorial.id,
          ...(accessorial.accessorial_type !== "general" && {
            accessorial_type: accessorial.accessorial_type.replace(" ", ""),
          }),
        };
        await this.$store.dispatch("load/accessorials/createAccessorialsByLoad", {
          id: loadId,
          body,
          departmentId: this.departmentId,
        });
        this.vuexAccessorials = this.$store.getters["load/accessorials/getClassifiedAccessorials"];
        this.accessorials = JSON.parse(JSON.stringify(this.vuexAccessorials));
      }
    },
    deleteAccessorialRequest(accessorial) {
      if (this.$options.filters.checkPermission("delete:load-accessorial")) {
        const body = {
          load_id: this.$route.params.loadId,
          accessorial_id: accessorial.revenova_id,
        };
        this.$store.dispatch("load/accessorials/deleteAccessorialsByLoad", body);
      }
      return null;
    },
    checkAccessorial({ accessorial, type, value }) {
      if (value && ['Blind Pick Up', 'Blind Delivery'].includes(accessorial.name)) {
        this.isBlindAccessorial(accessorial);
      }
      this.selectedAccessorialsVmodel[type][accessorial.id] = value;
      this.selectAccessorial(value, accessorial);
    },
    isBlindAccessorial(accessorial) {
      const oppositeNames = {
        "Blind Pick Up": "Blind Delivery",
        "Blind Delivery": "Blind Pick Up"
      };
      const allAccessorials = this.$store.getters["load/accessorials/getAccessorials"](-1);
      const oppositeAccessorial = allAccessorials.find((item) => item.name === oppositeNames[accessorial.name]);
      if (!oppositeAccessorial) return;
      if (this.selectedAccessorialsVmodel[oppositeAccessorial.accessorial_type.split(" ").join("")][oppositeAccessorial.id]) {
        this.selectedAccessorialsVmodel[oppositeAccessorial.accessorial_type.split(" ").join("")][oppositeAccessorial.id] = false;
        this.selectAccessorial(false, oppositeAccessorial);
      }
    }
  },
};
